import React from "react";
import Grid from "@cuda-networks/bds-core/dist/Grid";
import IAccount from "../../models/IAccount";
import MspType from "../../models/MspType";
import CheckBoxWithIndeterminateState from "../CheckBoxWithIndeterminateState";

interface IAccountCellBasicProps {
  showCheckbox: boolean;
  item: IAccount;
  disabled: boolean;
  onClick: (item: IAccount) => void;
  onCheck: (item: IAccount, checked: boolean) => void;
}

const AccountCellBasic: React.FC<IAccountCellBasicProps> = ({ item, disabled, showCheckbox, onClick, onCheck }) => {
    const textWeightStyle = getTextWeight(item.type);

  const onHandleChange = (checked: boolean): void => {
    onCheck(item, checked);
  };

  const handleOnClick = () => {
    onClick(item);
  };

  return (
    <td>
      <Grid item container style={{marginLeft: -30, maxHeight: 35}}>
        {showCheckbox && (
          <Grid container item xs={1}>
            {showCheckbox && <CheckBoxWithIndeterminateState size="small" disabled={disabled} onCheck={onHandleChange} checkedState={item.checkedState} />}
          </Grid>
        )}
        <Grid style={{ cursor: "pointer", marginBottom: 3 }} container item xs={10} onClick={handleOnClick}>        
            <div style={{ display: "flex", alignItems: "center" }}>              
                <div data-testid="accountName" className="noWrap" style={{ ...textWeightStyle, flex: 1, minWidth: 0, marginLeft: 5, fontSize: 15 }}>
                  {item.name}
                </div>
            </div>       
        </Grid>        
      </Grid>
    </td>
  );
};

export default AccountCellBasic;
export function getTextWeight(type: string) {
  let result = { fontWeight: "medium" };
 
    if (type === MspType.Partner) {
      result = { fontWeight: "bold" };
    } else if (type === MspType.Subpartner) {
      result = { fontWeight: "600" };
    }
  
    return result;
}
